.Cosmetic {
    background-color: rgba(244, 243, 255, 0.80);
    padding-bottom: 50px;
}

.Project-header {
    padding-top: 50px;
}

.Project2-description {
    line-height: 160%;
}

.Cosmetic-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    text-align: center;
}

.Promo-images-wrap { 
    margin: auto;
    text-align: center;
    justify-content: center;
    flex-wrap: wrap;
    display: flex;
}

.Cosmetic-img {
    display: block;
    box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.15);
    max-width: 80%;
    margin-bottom: 20px;
}

@media only screen and (max-width: 1400px) {
    .Cosmetic-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
  
}
@media only screen and (max-width: 450px) {
    .Project-header {
        padding-top: 20px;
    }
    
    .Cosmetic {
        padding-bottom: 10px;
    }

    .Cosmetic-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
  }