.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.Container {
  max-width: 1440px;
  padding-left: 60px;
  padding-right: 60px;
  margin-left: auto;
  margin-right: auto;
}

.second-caption {
  text-align: center;
}

.App-link {
  color: #61dafb;
}

.Primary-button {
  cursor: pointer;
  display: inline-block;
  padding: 15px 70px;
  border: 1px solid #363939;
  font-size: 18px;
  background-color: transparent;
  text-decoration: none;
  color: #363939;
}

.Primary-button:hover {
  background-color: #ffe393;
  border: 1px solid #ffe393;
  text-decoration: underline;
}

.Secondary-button {
  cursor: pointer;
  display: inline-block;
  padding: 15px 70px;
  font-size: 18px;
  background-color: #ffe393;
  border: 1px solid #ffe393;
  text-decoration: none;
  color: #363939;
  text-wrap: nowrap;
}

.Secondary-button-right {
  margin-left: 20px;
}

.Secondary-button:hover {
  cursor: pointer;
  display: inline-block;
  padding: 15px 70px;
  border: 1px solid #363939;
  font-size: 18px;
  background-color: transparent;
  text-decoration: underline;
}

@media only screen and (max-width: 768px) {
  .Hero-buttons {
      display: flex;
      flex-direction: column;
      margin-top: 50px;
    }
    .Secondary-button {
      margin-top: 10px;
      font-size: 16px;
      padding: 10px 0px;
      width: 200px;
      text-align: center;
    }
    .Secondary-button-right {
      margin-left: 0;
    }
    .Primary-button {
      margin-top: 10px;
      font-size: 16px;
      padding: 10px 0px;
      width: 200px;
      text-align: center;
    }}

@media only screen and (max-width: 450px) {
  .Container {
    padding-left: 20px;
    padding-right: 20px;
  }
}



