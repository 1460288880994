.KittensForSale-description-wrap {
    display: flex;
    margin-top: 50px;
    padding-top: 30px;
}
.kittens-promo-img {
    max-width: 100%;
}

.KittensForSale {
    padding-bottom: 40px;
}

.Kittens-project-description-text {
    margin-left: 50px;
}

.kittens-logo {max-height: 96px;
    max-width: 411px;}
    @media only screen and (max-width: 900px) {
        .KittensForSale-description-wrap {
            flex-direction: column;
        }
        .KittensForSale-content {
            text-align: center;
        }
        .kittens-logo {
            margin: auto;
        }
        .Kittens-project-description-text {
            margin-left: 0px;
        } 
    }
    @media only screen and (max-width: 450px) {
        .KittensForSale-description-wrap {
            flex-direction: column;
            margin-top: 20px;
        } 
        .kittens-logo {
            width: 100%;
        }
        .Kittens-project-description-text {
            margin-left: 0px;
        }
        .kittens-project-header {
            font-size: 18px;
        }
    }