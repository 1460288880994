.RetouchPromo-img-2 {
    max-height: 500px;
    max-width: 500px;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
}

.RetouchPromo-img {
    max-height: 650px;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    margin-top: 20px;
}


.RetouchPromo-wrap {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-bottom: 40px;
    border-bottom: solid 1.5px #E8E8E8;
}
.RetouchPromo-header {text-align: center;
    margin-top: 50px;}

    @media only screen and (max-width: 1160px) {
        .RetouchPromo-wrap { flex-direction: column;}
        .RetouchPromo-img {margin-bottom: 20px;}
    }

@media only screen and (max-width: 450px) {
    .RetouchPromo-wrap {
        flex-direction: column;
    }
    .RetouchPromo-img {
       width: 90%;
    }
    .RetouchPromo-img-2 {
        width: 100%;
     }

}