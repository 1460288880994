
.BrooklynCake 
{
    padding-bottom: 20px;
}
.BrooklynCake-description-wrap {
    display: flex;
}


.brooklyn-cake-img{
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
    max-width: 650px;
    display: block;
    }
.img-no-shadow {
    max-width:400px;
    margin-bottom: 30px;
}
.Img-bc-web-wrap {
    display: flex;
    justify-content: space-between;
}
.Brooklyn-project-description {
    margin-right: 100px;
    max-width: 700px;
}
.packs-cake-wrap {
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
}
.pack-cake-img {
    max-width: 650px;
    display: block;
}
.packs-cake-capture {
    text-align: center;
    font-size: 20px;
    font-weight: 600;
}

@media only screen and (max-width: 1440px) {
    .BrooklynCake-description-wrap {
        flex-direction: column;
    }
    .Brooklyn-project-description {
        max-width: 100%;
    }
    .img-no-shadow {
        margin: auto;
    }
    .Img-bc-web-wrap {flex-direction: column;
    align-items: center;}
    .brooklyn-cake-img{ margin-top: 40px;
    width: 100%;};
}


@media only screen and (max-width: 450px) {
    .Img-bc-web-wrap {
        flex-direction: column;
    }
    .BrooklynCake-description-wrap {
        flex-direction: column;
    }
    .img-no-shadow {
        width: 100%;
        margin-bottom: 10px
    }
    .brooklyn-cake-img {
        display: block;
        margin-top: 20px;
        margin-bottom: 20px;
        width:100%;
        height: 100%;
    }

    .Lucky-description-wrap {
        flex-direction: column;
        flex-wrap: wrap;
    }
    .Lucky-Gift-mock {
        width:100%;
        height: 100%;
    }
    .pack-cake-img {
        display: block;
        margin-top: 20px;
        margin-bottom: 20px;
        width:100%;
        height: 100%;
    }
    .packs-cake-wrap{
        flex-direction: column;
        margin-top: 0px;
        margin-bottom: 0px;
    }
    .packs-cake-capture {
        text-align: center;
        font-size: 16pxpx;
        font-weight: 600;
        margin: 0px;
    }
}