.Hero-section {
  background: radial-gradient(
    ellipse at right bottom,
    rgba(255, 227, 147, 0.65) 0%,
    rgba(255, 248, 239, 0.75) 100%
  );
  height: calc(100vh - 90px);
}
.Hero-content {
    /* position: relative; */
    display: flex;
    height: calc(100vh - 90px);
    flex-direction: column;
   justify-content:  center;
    z-index: 2;
}

.descriprion-wrap {
  max-width: 700px;
  display: inline-block;
}
.Aleksa-logo {
  width: 85%;
}

.Design-position {
  font-size: 2em;
  font-weight: 200;
  margin-top: 25px;
  margin-bottom: 50px;
}

.Description {
    max-width: 600px;
  font-size: 18px;
  line-height: 160%;
}

.Hero-buttons {
  display: flex;
  margin-top: 115px;
  flex-wrap: wrap;
}

@media screen and (max-device-width: 1439px) {
  .Hero-section {
    height: 100vh;
  }
  .Hero-buttons {
    display: flex;
    margin-top: 60px;
  }

}

@media only screen and (max-width: 768px) {
    .Hero-buttons {
        display: flex;
        flex-direction: column;
        margin-top: 50px;
      }
      .Design-position {
        font-size: 2em;
        font-weight: 200;
        margin-top: 20px;
        margin-bottom: 20px;
      }
      .Description {
        font-size: 16px;
        line-height: 140%;
      }
      .Aleksa-logo {
        width: 100%;
      }
}

@media only screen and (max-width: 425px) {
  .Aleksa-logo {
    margin-top: 45px;
    width: 100%;
  }
  .Description {
    font-size: 14px;
    line-height: 140%;
  }
  .Design-position {
    font-size: 2em;
    font-weight: 200;
    margin-top: 10px;
    margin-bottom: 15px;
  }
  .Hero-buttons {
    margin-top: 0px;
    margin-bottom: 10px;
  }
}
