
.Calendar-content {
    text-align: center;
    margin: auto;
}

.Calendar-img-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
}
.Calendar-cover-img {
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
}

.Calendar-img {
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
}


.Calendar-header {text-align: center;
margin-top: 50px;}

@media only screen and (max-width: 450px) {
   
}