.Londontown-wrap {
  text-align: center;
}
.img-capture-top {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
  margin-top: 0px;
  text-align: center;
  color: #9165b8;
}

.londontown-img {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
  max-width: 650px;
  display: block;
}
.londontown-img-email {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
  max-width: 650px;
  display: block;
  margin: auto;
}
.before-after-wrap {
    margin-bottom: 40px;
}
@media only screen and (max-width: 1440px) {
  .londontown-img {
    margin-top: 10px;
    margin-bottom: 20px;
    width: 100%;
  }
  .londontown-img-email {
    width: 100%;
  }
}

@media only screen and (max-width: 450px) {

  .londontown-img {
    display: block;
    margin-top: 5px;
    margin-bottom: 10px;
    width: 100%;
    height: 100%;
  }
  .londontown-img-email {
    width: 100%;
  }
}
