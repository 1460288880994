.Img-lucky-web {
    text-align: center;
    padding-top: 30px;
    padding-bottom: 30px;
}

.Img-lucky-web-wrap {
    display: flex;
    justify-content: space-around;
    padding-bottom: 30px;
}

.Lucky-description-wrap {
    display: flex;
}

.Lucky-Gift-mock {
    margin-top: auto;
    max-width: 40%;
    height:max-content
}

.LuckyGift-img{
    box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.15);
    width:646px;
    height:740px;
    }
.LuckyGift-img-3 {
    box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.15);
    width:790px
}
@media only screen and (max-width: 950px) {
    .Lucky-description-wrap {
        flex-direction: column;
        flex-wrap: wrap;
        text-align: center;
    }
    .Lucky-Gift-mock {
        margin: auto;
        max-width: 95%;
    }

}


@media only screen and (max-width: 450px) {
    .LuckyGift-img{
        width:100%;
        height: 100%;
        }
    .LuckyGift-img-3 {
        width:100%;
        height: 100%;
    }
    .Img-lucky-web-wrap {
        flex-direction: column;
        flex-wrap: wrap;
    }
    .Lucky-Gift-mock {
        width:100%;
        height: 100%;
    }
}