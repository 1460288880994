.SockHub-img {
    max-width: 536px;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
}


.SockHub-wrap {
    display: flex;
    justify-content: space-around;
}
.SockHub-header {text-align: center;
    margin-top: 50px;}

@media only screen and (max-width: 450px) {
    .SockHub-wrap {
        flex-direction: column;
    }
    .SockHub-img {
       width: 100%;
    }

}