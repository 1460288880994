.Project-header {
    
}

.GoBackButton-wrap {
    padding-top: 40px;
}

.Project-description {
    line-height: 160%;
}
.Img-gallery
{
    display: flex;
    justify-content: space-between;
    margin-top: 35px;
    margin-bottom: 70px;
    overflow: auto;
}
.Project-img {
    margin-right: 25px;
    max-width: 340px;
}

.Project-img:last-child {
    margin-right: 0px;
}

@media only screen and (max-width: 450px) {
    .Project-header {
        margin-top: 20px;
    }
    
    .Project-description {
        line-height: 140%;
        margin-left: 0px;
    }
    .Img-gallery
    {
        margin-top: 25px;
        margin-bottom: 40px;
        overflow: auto;
    }
    .Project-img {
        margin-right: 25px;
        max-width: 200px;
        margin-right: 7px;
    }
    
    .Project-img:last-child {
        margin-right: 0px;
    }
}    